<template>
  <div class="container">
    <div class="card" id="box">
      <div class="card-header" id="box_title">
        <h5>
          <span v-if="isEdit">Edycja</span>
          <span v-if="!isEdit">Utwórz</span>
          <div class="spinner-border spinner-border-sm text-primary" v-if="isLoading"></div>
        </h5>
        <div class="card-header-right" id="box_title_buttons"></div>
      </div>
      <div class="card-body" v-if="!isLoading">
        <div :class="{ color_danger: validation.name }" class="form-group row mb-3">
          <label class="col-sm-4 col-form-label"> Key name </label>
          <div class="col-8">
            <input class="form-control" name="name" type="text" v-model="item.name" />
          </div>
        </div>
        <div :class="{ color_danger: validation.value }" class="form-group row mb-3">
          <label class="col-sm-4 col-form-label"> Wartość </label>
          <div class="col-8">
            <input class="form-control" name="value" type="text" v-model="item.value" />
          </div>
        </div>
        <div :class="{ color_danger: validation.dictionary_name }" class="form-group row mb-3">
          <label class="col-sm-4 col-form-label"> Miniatura </label>
          <div class="col-8">
            <img height="50" :src="'https://sync.krestonmeble.pl/' + item.dictionary_image"
              v-if="item.dictionary_image" />
            <input class="form-control" name="dictionary_image" type="file" @change="handleFilesUpload($event)" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 text-end">
            <button @click.prevent="clickSave" class="btn btn-success">Zapisz</button>
          </div>
          <div class="col-6">
            <router-link :to="'/dictionary/' + dictionary_id + '/items'" class="btn btn-outline-secondary me-1" title="">
              Zamknij
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../api/axios';

export default {
  template: "#shop_dictionary_edit-template",
  components: {},
  data: function () {
    return {
      files: null,
      error: {},
      isLoading: true,
      isEdit: false,
      dictionary_id: 0,
      item: {
        dictionary_name: "",
        dictionary_image: "",
      },
      validation: {
        dictionary_name: false,
        dictionary_image: "",
      },
      fileModal: false,
    };
  },
  created: function () { },
  mounted: function () {
    this.dictionary_id = this.$route.params.dictionary_id;
    if (this.$route.params.id) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.getPost();
    } else {
      this.isLoading = false;
      this.isEdit = false;
      this.id = 0;
    }
  },
  computed: {},
  methods: {
    handleFilesUpload(event) {
      console.log('files', event.target.files)
      this.files = event.target.files
    },
    lang: function (key) {
      return key;
      // return MAGICO_PANEL.langs.get(key);
    },
    clickSave: function () {
      this.save();
    },
    save: function () {
      this.validation = {
        dictionary_name: false,
        dictionary_status: false,
      };
      var t = this;
      var promise;


      if (this.isEdit) {
        promise = axios.put("api/dictionary/" + this.dictionary_id + '/items/' + this.id, this.item, {
        }).then(() => {
          t.$router.push("/dictionary/" + this.dictionary_id + '/items');
          this.$toast('Zapisano poprawnie')
        });
      } else {
        promise = axios.post("api/dictionary/" + this.dictionary_id + '/items', this.item, {

        }).then(() => {
          t.$router.push("/dictionary/" + this.dictionary_id + '/items');
          this.$toast('Zapisano poprawnie')
        });
      }
      promise.catch((error) => {
        console.log(error);
        if (error.response.status === 403) {
          var msg = "Brak uprawnień";
          this.$toast(msg);
        }
        if (error.response.status === 422) {
          Object.keys(error.response.data.dictionarys).forEach(function eachKey(key) {
            t.validation[key] = error.response.data.dictionarys[key];
          });
        }
      });
    },
    getPost: function () {
      // var t = this;
      this.isLoading = true;
      axios
        .get("api/dictionary/" + this.dictionary_id + '/items/' + this.id)
        .then((response) => {
          this.item = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          var msg = error.response.statusText;
          if (error.response.status === 403) {
            msg = "Brak uprawnień";
          }
          this.$toast(msg);
        });
    },
  },
};
</script>
